import styled from "styled-components";

export default styled.article`
  padding: 3rem;
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 0 50px 0 rgba(175, 172, 189, 0.5);

  p:last-child,
  & > :last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  & .grvsc-container {
    background: #f6f8fa;
  }
`;
