import React from 'react'
import { TimelineLite, CSSPlugin } from 'gsap/all'
import { Container } from './styles'

// treeshake resist
const plugins = [CSSPlugin]

export default function StaggeredSvg ({ children, rawSvg, duration = 4, durationPerElement = 0.9 }) {
	const hero = React.useRef(null)

	const animate = () => {
		if (!hero || !hero.current) return

		const g = hero.current.querySelectorAll('circle,line,polygon,polylie,rect,ellipse,path')
		if (!g.length) return

		const lastFire = duration - durationPerElement
		const elementCount = g.length
		const staggerTime = lastFire / elementCount

		new TimelineLite().staggerFrom(g, durationPerElement, {
			css: {
				autoAlpha: 0,
				scale: 0.5,
				transformOrigin: 'center center'
			},
			ease: 'Back.easeOut'
		}, staggerTime)
	}

	React.useEffect(() => {
		animate()
	}, [hero, duration, durationPerElement])

	if (rawSvg) {
		return <Container
			ref={hero}
			dangerouslySetInnerHTML={{
				__html: rawSvg
			}}
		/>
	}

	return (
		<Container ref={hero}>
			{children}
		</Container>
	)
}
