import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.header`
  background: white;
  margin-bottom: 1.45rem;
  border-bottom: 2px solid rgb(31, 166, 250);
`;

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  max-width: 925px;
  padding: 1rem 1.0875rem;
`;

export const LeftMenu = styled.div`
  flex: 1;
`;

export const PrimaryLink = styled(Link)`
  font-size: 1.66rem;
  color: rgb(43, 46, 81);
`;

export const SecondaryLink = styled(Link)`
  font-size: 1.2rem;
  color: rgb(43, 46, 81);
  margin-left: 1.5rem;
`;

export const OutsideLink = styled.a`
  font-size: 1.2rem;
  color: rgb(43, 46, 81);
  margin-left: 1.5rem;
`;

export const RightMenu = styled.div``;
