import styled, { keyframes } from 'styled-components'

const revealFromTop = keyframes`
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
`

const arrowSize = `8px`
const color = `#ec5e5e`

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`

export const Inner = styled.div`
	transform: scale(0);
	color: white;
	position: absolute;
	top: calc(100% + 1rem);
	left: 3rem;
	font-size: 1rem;
	font-style: italic;
	padding: 0.5rem;
	border-radius: 4px;
	background-color: ${color};
	animation: ${revealFromTop} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards 1;
	animation-delay: 2s;
	transform-origin: top left;

	&:before {
		content: '';
		position: absolute;
		top: -${arrowSize};
		width: 0;
		height: 0;
		border-left: ${arrowSize} solid transparent;
		border-right: ${arrowSize} solid transparent;
		border-bottom: ${arrowSize} solid ${color};
	}
`
