import React from 'react'
import { Container, Inner } from './styles'

export default function SpeechBubble ({ children }) {
	return (
		<Container>
			<Inner>
				{children}
			</Inner>
		</Container>
	)
}
