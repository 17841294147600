import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	height: 100%;
	z-index: -1;

	img, svg {
		width: 100%;
		height: 100%;
	}

	svg {
		overflow: visible !important;
		z-index: -1;

		circle, line, polygon, polyline, rect, ellipse, path {
			visibility: hidden;
		}
	}
`
