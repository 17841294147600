/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from './SchemaOrg'

function SEO({ description, lang, meta, title, imageUrl, frontmatter = {}, excerpt }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
						excerpt
					}
				}
			}
		`
	)

	const image = imageUrl
		? `${site.siteMetadata.siteUrl}${imageUrl}`
		: `${site.siteMetadata.siteUrl}/default-seo.jpg`

	const isBlogPost = Boolean(frontmatter.path)
	const metaDescription = excerpt || description || site.siteMetadata.excerpt
	const url = frontmatter.path ? `${site.siteMetadata.siteUrl}${frontmatter.path}` : site.siteMetadata.siteUrl
	const datePublished = isBlogPost ? frontmatter.date : false

	const solidMeta = [{
		name: `image`,
		content: image
	}, {
		name: `description`,
		content: metaDescription,
	}, {
		property: `og:url`,
		content: url
	}, {
		property: `og:title`,
		content: title || site.siteMetadata.title,
	}, {
		property: `og:description`,
		content: metaDescription,
	}, {
		property: `og:image`,
		content: image
	}, {
		name: `twitter:card`,
		content: `summary`,
	}, {
		name: `twitter:creator`,
		content: `atticjack`,
	}, {
		name: `twitter:title`,
		content: title || site.siteMetadata.title,
	}, {
		name: `twitter:description`,
		content: metaDescription,
	}, {
		name: `twitter:image`,
		content: image
	}]

	if (isBlogPost) solidMeta.push({
		property: `og:type`,
		content: `article`
	})

	const titleProps = {
		title: title || site.siteMetadata.title
	}

	if (title) {
		titleProps.titleTemplate = `%s | ${site.siteMetadata.title}`
	}

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang,
				}}
				{...titleProps}
				meta={solidMeta.concat(meta)}
				noscript={[{
					innerHTML: `<style type="text/css">#gatsby-noscript{display: none}polygon,rect,ellipse,path{visibility: visible !important}</style>`
				}]}
			/>

			<SchemaOrg
				siteUrl={site.siteMetadata.siteUrl}
				datePublished={datePublished}
				defaultTitle={site.siteMetadata.title}
				description={metaDescription}
				isBlogPost={isBlogPost}
				title={title || site.siteMetadata.title}
				url={url}
				image={image}
			/>
		</>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO
