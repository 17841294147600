import React from 'react'
import Helmet from 'react-helmet'

export default React.memo(({
	siteUrl,
	datePublished,
	defaultTitle,
	description,
	image,
	isBlogPost,
	title,
	url
}) => {
	const baseSchema = [{
		'@context': `http://schema.org`,
		'@type': `WebSite`,
		url,
		name: title,
		alternateName: defaultTitle
	}]

	const schema = isBlogPost
		? [...baseSchema, {
			'@context': `http://schema.org`,
			'@type': `BreadcrumbList`,
			itemListElement: [{
				'@type': `ListItem`,
				position: 1,
				item: {
					'@id': url,
					name: title,
					image
				}
			}]
		}, {
			'@content': `http://schema.org`,
			'@type': `BlogPosting`,
			url,
			name: title,
			alternateName: defaultTitle,
			headline: title,
			image: {
				'@type': `ImageObject`,
				url: image
			},
			description,
			author: {
				'@type': `Person`,
				name: `Jack Williams`
			},
			mainEntityOfPage: {
				'@type': `WebSite`,
				'@id': siteUrl,
			},
			datePublished
		}]
		: baseSchema

	return (
		<Helmet>
			{/* Schema.org tags */}
			<script type="application/ld+json">{JSON.stringify(schema)}</script>
		</Helmet>
	)
})
