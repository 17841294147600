import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  Wrapper,
  RightMenu,
  LeftMenu,
  PrimaryLink,
  SecondaryLink,
  OutsideLink,
} from "./styles";
import SpeechBubble from "../SpeechBubble/index";

const Header = ({ siteTitle }) => (
  <Container>
    <Wrapper>
      <LeftMenu>
        <PrimaryLink to="/">{siteTitle}</PrimaryLink>

        <noscript>
          <SpeechBubble>
            Psst! This site's way cooler with JavaScript...
          </SpeechBubble>
        </noscript>
      </LeftMenu>

      <RightMenu>
        <OutsideLink href="https://midi-mixer.com" target="_blank">
          MIDI Mixer
        </OutsideLink>
        <SecondaryLink to="/about">About</SecondaryLink>
      </RightMenu>
    </Wrapper>
  </Container>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
